import { Badge, Td, Text, Tr, useColorMode } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { collectionHashToName, chainIDToName } from "utils/constants";

const BlockRow = ({ decodedBlockDetails, lastItem }) => {
  const {
    collectionId,
    collectionNameHash,
    power,
    requestId,
    result,
    requestingChainDomain,
    destinationChainDomain,
    requestType,
    message,
  } = decodedBlockDetails;

  const colorStatus = useColorMode("white", "gray.400");

  return (
    <Tr>
      <Td
        border={lastItem ? "none" : null}
        borderBottomColor="#56577A"
        ps="0px"
      >
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {requestId ? (
            <Link to={`/requests/${requestId}`}>{requestId}</Link>
          ) : (
            "-"
          )}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {chainIDToName[requestingChainDomain] || requestingChainDomain || "-"}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {chainIDToName[destinationChainDomain] ||
            destinationChainDomain ||
            "-"}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          <Badge
            fontSize="sm"
            p="3px 10px"
            borderRadius="8px"
            fontWeight="bold"
          >
            {requestType || "-"}
          </Badge>
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {message || "-"}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {collectionId || "-"}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {result || "-"}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {power || "-"}
        </Text>
      </Td>

      <Td border={lastItem ? "none" : null} borderBottomColor="#56577A">
        <Text fontSize="sm" color="#fff" fontWeight="bold">
          {collectionHashToName[collectionNameHash] ||
            collectionNameHash ||
            "-"}
        </Text>
      </Td>
    </Tr>
  );
};

export default BlockRow;
