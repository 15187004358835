import {
  Flex,
  SimpleGrid,
  Skeleton,
  Spacer,
  Stat,
  StatLabel,
  StatNumber,
  Text,
} from "@chakra-ui/react";

// Styles for the circular progressbar
// Custom components
import CardBody from "components/Card/CardBody.js";
// Icons

import { useLazyQuery } from "@apollo/client";
import Card from "components/Card/Card";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FETCH_REQUEST } from "utils/queries";
import ResultDetails from "./ResultDetails";

const Header = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [requestData, setRequestData] = useState();
  const [resultDetails, setResultDetails] = useState(null);
  const { requestId } = useParams();
  const [getRequestData, { data }] = useLazyQuery(FETCH_REQUEST, {
    variables: {
      requestId: requestId,
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    fetchRequest();
  }, [requestId]);

  useEffect(() => {
    console.log({ requestData });
  }, [requestData]);

  useEffect(() => {
    console.log(data?.requests);
    if (data?.requests[0]) {
      setRequestData(data?.requests[0]);
      setResultDetails({
        requestingChain: data.requests[0].resultRequestingChainDomain,
        destinationChain: data.requests[0].resultDestinationChainDomain,
        target: data.requests[0].resultTarget,
        result: data.requests[0].resultValues,
      });
    }
  }, [data]);

  const fetchRequest = async () => {
    await getRequestData();
  };

  return (
    <>
      <SimpleGrid columns={{ sm: 1, md: 2, xl: 4 }} spacing="24px">
        {/* MiniStatistics Card */}
        <Card>
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb="2px"
                >
                  Request ID
                </StatLabel>
                <Flex>
                  {requestId ? (
                    <Text fontWeight="bold">{requestId}</Text>
                  ) : (
                    <Skeleton
                      height="30px"
                      borderRadius={"5px"}
                      width="200px"
                    ></Skeleton>
                  )}
                </Flex>
              </Stat>
            </Flex>
          </CardBody>
        </Card>
        {/* MiniStatistics Card */}
        <Card minH="83px">
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat me="auto">
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  pb="2px"
                >
                  Status
                </StatLabel>
                <Flex>
                  {isLoading ? (
                    <Skeleton
                      height="30px"
                      borderRadius={"5px"}
                      width="200px"
                    ></Skeleton>
                  ) : (
                    <Text fontWeight="bold">{requestData?.status ?? "-"}</Text>
                  )}
                </Flex>
              </Stat>
            </Flex>
          </CardBody>
        </Card>
        {/* MiniStatistics Card */}
        <Card>
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat>
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  // pb="2px"
                >
                  Num Commits
                </StatLabel>
                <Flex>
                  {isLoading ? (
                    <Skeleton
                      height="30px"
                      borderRadius={"5px"}
                      width="200px"
                    ></Skeleton>
                  ) : (
                    <Text fontWeight="bold">
                      {requestData?.numCommits ?? "-"}
                    </Text>
                  )}
                </Flex>
              </Stat>
              <Spacer />
            </Flex>
          </CardBody>
        </Card>

        {/* MiniStatistics Card */}
        <Card>
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat>
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  // pb="2px"
                >
                  Finalized Epoch
                </StatLabel>
                <Flex>
                  {isLoading ? (
                    <Skeleton
                      height="30px"
                      borderRadius={"5px"}
                      width="200px"
                    ></Skeleton>
                  ) : (
                    <Text fontWeight="bold">
                      {requestData?.requestFinalizedEpoch ?? "-"}
                    </Text>
                  )}
                </Flex>
              </Stat>
              <Spacer />
            </Flex>
          </CardBody>
        </Card>

        {/* MiniStatistics Card */}
        <Card>
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat>
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  // pb="2px"
                >
                  Consensus Reached Epoch
                </StatLabel>
                <Flex>
                  {isLoading ? (
                    <Skeleton
                      height="30px"
                      borderRadius={"5px"}
                      width="200px"
                    ></Skeleton>
                  ) : (
                    <Text fontWeight="bold">
                      {requestData?.epochConsensusReached ?? "-"}
                    </Text>
                  )}
                </Flex>
              </Stat>
              <Spacer />
            </Flex>
          </CardBody>
        </Card>

        {/* MiniStatistics Card */}
        <Card>
          <CardBody>
            <Flex flexDirection="row" align="center" justify="center" w="100%">
              <Stat>
                <StatLabel
                  fontSize="sm"
                  color="gray.400"
                  fontWeight="bold"
                  // pb="2px"
                >
                  First Committed Epoch
                </StatLabel>
                <Flex>
                  {isLoading ? (
                    <Skeleton
                      height="30px"
                      borderRadius={"5px"}
                      width="200px"
                    ></Skeleton>
                  ) : (
                    <Text fontWeight="bold">
                      {requestData?.requestFirstCommittedEpoch ?? "-"}
                    </Text>
                  )}
                </Flex>
              </Stat>
              <Spacer />
            </Flex>
          </CardBody>
        </Card>
      </SimpleGrid>
      <ResultDetails result={resultDetails} isLoading={isLoading} />
    </>
  );
};

export default Header;
