import { ethers } from "ethers";
import BridgeABI from "@razor-network/bridge-contracts-snapshot/abi/Bridge.json";
import StakeManagerABI from "@razor-network/bridge-contracts-snapshot/abi/StakeManager.json";
import DelegatedTokenABI from "@razor-network/bridge-contracts-snapshot/abi/DelegatedToken.json";
import BridgeTokenABI from "@razor-network/bridge-contracts-snapshot/abi/BridgeToken.json";
import addresses from "@razor-network/bridge-contracts-snapshot/deployments/info/testnet/native.json";
import { RPC_URL } from "./constants";
import moment from "moment";

export const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
export const EPOCH_LENGTH = 180;
export const DYNASTY_LENGTH = 20;

const bridge = new ethers.Contract(
  addresses[837567383277].Bridge,
  BridgeABI,
  provider
);
const STAKE_MANAGER_ADDRESS = addresses[837567383277].StakeManager;
const BRIDGE_TOKEN_ADDRESS = addresses[837567383277].BridgeToken;

export const getMode = async () => {
  try {
    const mode = await bridge.getMode();
    return mode;
  } catch (error) {
    console.log("error occured while fetching mode:", error);
  }
};

export const getEpoch = async () => {
  try {
    const epoch = await bridge.getEpoch();
    return epoch.toNumber();
  } catch (error) {
    console.log("error occured while fetching epoch:", error);
  }
};

export const getDynasty = async () => {
  try {
    const dynasty = await bridge.getDynasty();
    return dynasty.toNumber();
  } catch (error) {
    console.log("error occured while fetching dynasty:", error);
  }
};

export const getSignerAddressPerDynasty = async (dynasty) => {
  try {
    const signerAddress = await bridge.signerAddressPerDynasty(dynasty);
    return signerAddress;
  } catch (error) {
    console.log("error occured while fetching signer address:", error);
  }
};

export const getLatestBlockTimestamp = async () => {
  try {
    const block = await provider.getBlock("latest");
    console.log({ block });
    return block.timestamp;
  } catch (error) {
    console.log("error occured while fetching block timestamp:", error);
  }
};

export const approveToken = async (amount) => {
  try {
    amount = ethers.utils.parseEther(amount.toString());
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
      BRIDGE_TOKEN_ADDRESS,
      BridgeTokenABI,
      signer
    );
    const approveTx = await tokenContract.approve(
      STAKE_MANAGER_ADDRESS,
      amount
    );
    if (!approveTx) {
      return {
        success: false,
        txHash: null,
        error: null,
      };
    }

    await approveTx.wait();
    return {
      success: true,
      txHash: approveTx.hash,
      error: null,
    };
  } catch (error) {
    console.log("error occured while approving tokens", error);
    return {
      success: false,
      txHash: null,
      error,
    };
  }
};

export const delegate = async (validatorId, amount) => {
  try {
    amount = ethers.utils.parseEther(amount.toString());
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const provider = new ethers.providers.JsonRpcProvider(
    //     "https://staging-v3.skalenodes.com/v1/staging-aware-chief-gianfar"
    //   );
    const signer = provider.getSigner();
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      signer
    );
    console.log(stakeManagerContract?.address, amount, validatorId);
    const delegateTx = await stakeManagerContract.delegate(
      parseInt(validatorId),
      amount
    );
    console.log(delegateTx);
    if (!delegateTx) {
      return {
        success: false,
        txHash: null,
        error: null,
      };
    }

    await delegateTx.wait();
    return {
      success: true,
      txHash: delegateTx.hash,
      error: null,
    };
  } catch (error) {
    console.log("Error occured while delegating tokens", error);
    return {
      success: false,
      txHash: null,
      error,
    };
  }
};

export const approveDToken = async (amount, dTokenAddress) => {
  try {
    amount = ethers.utils.parseEther(amount.toString());
    const provider = new ethers.providers.Web3Provider(window.ethereum);

    const signer = provider.getSigner();
    const tokenContract = new ethers.Contract(
      dTokenAddress,
      DelegatedTokenABI,
      signer
    );

    const approveTx = await tokenContract.approve(
      STAKE_MANAGER_ADDRESS,
      amount
    );
    if (!approveTx) {
      return {
        success: false,
        txHash: null,
        error: null,
      };
    }

    await approveTx.wait();
    return {
      success: true,
      txHash: approveTx.hash,
      error: null,
    };
  } catch (error) {
    console.log("error occured while approving tokens", error);
    return {
      success: false,
      txHash: null,
      error,
    };
  }
};

export const quitNetwork = async () => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      signer
    );
    const quitNetworkTx = await stakeManagerContract.quitNetwork();

    if (!quitNetworkTx) {
      return {
        success: false,
        txHash: null,
        error: null,
      };
    }

    await quitNetworkTx.wait();
    return {
      success: true,
      txHash: quitNetworkTx.hash,
      error: null,
    };
  } catch (error) {
    console.log("Error occured while quitting network", error);
    return {
      success: false,
      txHash: null,
      error,
    };
  }
};

export const signalWithdraw = async (validatorId, dAmount) => {
  try {
    dAmount = ethers.utils.parseEther(dAmount.toString());
    console.log(dAmount);
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      signer
    );

    const signalWithdrawTx = await stakeManagerContract.signalWithdraw(
      validatorId,
      dAmount
    );
    if (!signalWithdrawTx) {
      return {
        success: false,
        txHash: null,
        error: null,
      };
    }

    await signalWithdrawTx.wait();
    return {
      success: true,
      txHash: signalWithdrawTx.hash,
      error: null,
    };
  } catch (error) {
    console.log("Error occured while signal withdrawing tokens", error);
    return {
      success: false,
      txHash: null,
      error,
    };
  }
};

export const initiateWithdraw = async (validatorId) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      signer
    );

    const initiateWithdrawTx = await stakeManagerContract.initiateWithdraw(
      validatorId
    );
    if (!initiateWithdrawTx) {
      return {
        success: false,
        txHash: null,
        error: null,
      };
    }

    await initiateWithdrawTx.wait();
    return {
      success: true,
      txHash: initiateWithdrawTx.hash,
      error: null,
    };
  } catch (error) {
    console.log("Error occured while initiating withdraw tokens", error);
    return {
      success: false,
      txHash: null,
      error,
    };
  }
};

export const withdraw = async (validatorId) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      signer
    );

    const withdrawTx = await stakeManagerContract.executeWithdraw(validatorId);
    if (!withdrawTx) {
      return {
        success: false,
        txHash: null,
        error: null,
      };
    }

    await withdrawTx.wait();
    return {
      success: true,
      txHash: withdrawTx.hash,
      error: null,
    };
  } catch (error) {
    console.log("Error occured while executing withdraw tokens", error);
    return {
      success: false,
      txHash: null,
      error,
    };
  }
};

export const resetSignalLock = async (validatorId) => {
  try {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      signer
    );

    const resetSignalLockTx = await stakeManagerContract.resetSignalLock(
      validatorId
    );
    if (!resetSignalLockTx) {
      return {
        success: false,
        txHash: null,
        error: null,
      };
    }
    await resetSignalLockTx.wait();
    return {
      success: true,
      txHash: resetSignalLockTx.hash,
      error: null,
    };
  } catch (error) {
    console.log("error occured while resetting signal lock", error);
    return {
      success: false,
      txHash: null,
      error,
    };
  }
};

export const getdTokenBalance = async (tokenAddress, address) => {
  if (address) {
    try {
      const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
      const sRazorContract = new ethers.Contract(
        tokenAddress,
        BridgeTokenABI,
        provider
      );
      const balance = await sRazorContract.balanceOf(address);
      const balanceInEther = ethers.utils.formatEther(balance);
      return balanceInEther;
    } catch (error) {
      console.log("error while fetching razor balance");
      console.log(error);
      return 0;
    }
  }
};

// * lockType: 0 -> Unstake, 1 -> Withdraw
export const getDelegatorLock = async (msgSender, dtokenAddress, lockType) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      provider
    );
    const lock = await stakeManagerContract.delegatorLocks(
      msgSender,
      dtokenAddress,
      lockType
    );
    console.log(lock);
    return lock;
  } catch (error) {
    console.log("error occured while fetching delegator lock", error);
  }
};

export const getWithdrawInitiationPeriod = async () => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      provider
    );
    const period = await stakeManagerContract.withdrawInitiationPeriod();
    return period;
  } catch (error) {
    console.log(
      "error occured while fetching withdraw initiation period",
      error
    );
  }
};

export const getUnstakeLock = async (address, validatorDTokenAddress) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      provider
    );
    const lock = await stakeManagerContract.locks(
      address,
      validatorDTokenAddress,
      0
    );
    return lock;
  } catch (error) {
    console.log("error occured while fetching unstake lock", error);
  }
};

export const getWithdrawLock = async (address, validatorDTokenAddress) => {
  try {
    const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
    const stakeManagerContract = new ethers.Contract(
      STAKE_MANAGER_ADDRESS,
      StakeManagerABI,
      provider
    );
    const lock = await stakeManagerContract.locks(
      address,
      validatorDTokenAddress,
      1
    );
    return lock;
  } catch (error) {
    console.log("error occured while fetching unstake lock", error);
  }
};

export const convertTime = (
  epoch,
  dynasty,
  unlockAfter,
  unlockBefore,
  after
) => {
  if (epoch && dynasty) {
    if (after && dynasty < unlockAfter) {
      const completed = epoch % DYNASTY_LENGTH;
      const epochsRemaining = DYNASTY_LENGTH - completed;
      const timeRemaining =
        ((unlockAfter - dynasty) * DYNASTY_LENGTH +
          epochsRemaining -
          DYNASTY_LENGTH) *
        EPOCH_LENGTH;
      let hours = Math.floor(timeRemaining / 3600);
      let minutes = Math.floor((timeRemaining % 3600) / 60);
      return hours + "h " + minutes + "m";
    } else if (!after && dynasty < unlockBefore) {
      const completed = epoch % DYNASTY_LENGTH;
      const epochsRemaining = DYNASTY_LENGTH - completed;
      const timeRemainingAfter =
        ((unlockAfter - dynasty) * DYNASTY_LENGTH +
          epochsRemaining -
          DYNASTY_LENGTH) *
        EPOCH_LENGTH;
      const timeRemaining =
        timeRemainingAfter > 0
          ? ((unlockBefore - dynasty) * DYNASTY_LENGTH +
              epochsRemaining -
              DYNASTY_LENGTH) *
              EPOCH_LENGTH -
            timeRemainingAfter
          : ((unlockBefore - dynasty) * DYNASTY_LENGTH +
              epochsRemaining -
              DYNASTY_LENGTH) *
            EPOCH_LENGTH;
      let hours = Math.floor(timeRemaining / 3600);
      let minutes = Math.floor((timeRemaining % 3600) / 60);
      return hours + "h " + minutes + "m";
    }
  }
  return "0h 0m";
};

export const getETA = (timestamp, unlockAfter, unlockBefore, after) => {
  if (timestamp) {
    if (after) {
      return moment.unix(unlockAfter).from(moment.unix(timestamp));
    } else {
      return moment.unix(unlockBefore).from(moment.unix(timestamp));
    }
  }
  return "0h 0m";
};

export const getTimeFrom = (from, to) => {
  return moment.unix(from).from(moment.unix(to));
};
